// Path to post form to add people to mailchimp
// Use the MAILCHIMP_SUBSCRIBE_PATH for signung up for the newsletter
export var MAILCHIMP_BASE_PATH = "https://givingwhatwecan.us13.list-manage.com/subscribe/post?u=6f02b28df4f6b1dcab7652a6e";
var NEWSLETTER_AUDIENCE_ID = "26d459b774";
// Path to post form to subscribe to newsletter
// Automtically upserts SUB_NEWSL to "Yes" when person is already in the list
export var MAILCHIMP_SUBSCRIBE_PATH = MAILCHIMP_BASE_PATH + "&id=".concat(NEWSLETTER_AUDIENCE_ID) + "&SUB_NEWSL=Yes";
// URL to Mailchimp page integration js for newsletter popup
export var MAILCHIMP_POPUP_JS_URL = "https://chimpstatic.com/mcjs-connected/js/users/6f02b28df4f6b1dcab7652a6e/9d7a917eba5746e9b76dba0ed.js";
export var PLEDGE_SERIES_SUBSCRIBE_PATH = MAILCHIMP_BASE_PATH + "&id=".concat(NEWSLETTER_AUDIENCE_ID) + "&LEARN_PLDG=Yes";
