import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import { useGeoLocation } from "@aletheia/common/utils/hooks";
import { countryCookieName, defaultLocale, languageCookieName } from "../../../utils/config";
import { DEFAULT_COOKIE_OPTIONS } from "../../../utils/cookie";
export var useLocalization = function() {
    var router = useRouter();
    var urlLocale = router.locale || defaultLocale;
    var ref = useGeoLocation(), geolocatedCountry = ref.country;
    // Extract from URL
    var ref1 = _sliced_to_array((urlLocale === null || urlLocale === void 0 ? void 0 : urlLocale.split("-")) || [
        undefined,
        undefined, 
    ], 2), urlLanguage = ref1[0], urlCountry = ref1[1];
    // Extract from Cookie
    var ref2 = _sliced_to_array(useCookies([
        countryCookieName,
        languageCookieName, 
    ]), 2), cookies = ref2[0], setCookie = ref2[1];
    var cookieCountry = cookies[countryCookieName];
    var cookieLanguage = cookies[languageCookieName];
    var setCookieCountry = function(value) {
        return setCookie(countryCookieName, value, DEFAULT_COOKIE_OPTIONS);
    };
    var setCookieLanguage = function(value) {
        return setCookie(languageCookieName, value, DEFAULT_COOKIE_OPTIONS);
    };
    return {
        urlLocale: urlLocale,
        urlLanguage: urlLanguage,
        urlCountry: urlCountry,
        cookieCountry: cookieCountry,
        cookieLanguage: cookieLanguage,
        geolocatedCountry: geolocatedCountry,
        favoriteCountry: cookieCountry || geolocatedCountry,
        setCookieCountry: setCookieCountry,
        setCookieLanguage: setCookieLanguage
    };
};
